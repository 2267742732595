//KY
export const url0 = "https://hangzhouchang.oss-cn-hangzhou.aliyuncs.com/error";
export const url1 = "https://bceguang.gz.bcebos.com/error";
export const url2 = "https://cloudt.oss-cn-quanzhou.kz.cc/error";
export const url3 =
  "http://alb-f4r15f877eejshperb.ap-northeast-1.alb.aliyuncs.com/801/error";
export const url4 = "https://dbutdt.s3.ap-northeast-1.amazonaws.com/error";

export const VARIANT = "8801";
export const DOMAIN = "https://ky.qpdwebda.com";
// export const DOMAIN = "https://baidu.ac";
export const AGENT = "88ky";
export const APP_NAME = "开元棋牌";
