export const VipLevel = {
    1: {
        src: require("../../assets/commons/VipLevels/vip1.png"),
    },
    2: {
        src: require("../../assets/commons/VipLevels/vip2.png"),
    },
    3: {
        src: require("../../assets/commons/VipLevels/vip3.png"),
    },
    4: {
        src: require("../../assets/commons/VipLevels/vip4.png"),
    },
    5: {
        src: require("../../assets/commons/VipLevels/vip5.png"),
    },
    6: {
        src: require("../../assets/commons/VipLevels/vip6.png"),
    },
    7: {
        src: require("../../assets/commons/VipLevels/vip7.png"),
    },
    8: {
        src: require("../../assets/commons/VipLevels/vip8.png"),
    },
    9: {
        src: require("../../assets/commons/VipLevels/vip9.png"),
    },
    10: {
        src: require("../../assets/commons/VipLevels/vip10.png"),
    },
    11: {
        src: require("../../assets/commons/VipLevels/vip11.png"),
    },
    12: {
        src: require("../../assets/commons/VipLevels/vip12.png"),
    },
    13: {
        src: require("../../assets/commons/VipLevels/vip13.png"),
    },
    14: {
        src: require("../../assets/commons/VipLevels/vip14.png"),
    },
    15: {
        src: require("../../assets/commons/VipLevels/vip15.png"),
    },
    16: {
        src: require("../../assets/commons/VipLevels/vip16.png"),
    },
    17: {
        src: require("../../assets/commons/VipLevels/vip17.png"),
    },
    18: {
        src: require("../../assets/commons/VipLevels/vip18.png"),
    },
    19: {
        src: require("../../assets/commons/VipLevels/vip19.png"),
    },
    20: {
        src: require("../../assets/commons/VipLevels/vip20.png"),
    },
    21: {
        src: require("../../assets/commons/VipLevels/vip21.png"),
    },
    22: {
        src: require("../../assets/commons/VipLevels/vip22.png"),
    },
    23: {
        src: require("../../assets/commons/VipLevels/vip23.png"),
    },
    24: {
        src: require("../../assets/commons/VipLevels/vip24.png"),
    },
    25: {
        src: require("../../assets/commons/VipLevels/vip25.png"),
    },
    26: {
        src: require("../../assets/commons/VipLevels/vip26.png"),
    },
    27: {
        src: require("../../assets/commons/VipLevels/vip27.png"),
    },
    28: {
        src: require("../../assets/commons/VipLevels/vip28.png"),
    },
    29: {
        src: require("../../assets/commons/VipLevels/vip29.png"),
    },
    30: {
        src: require("../../assets/commons/VipLevels/vip30.png"),
    },
    31: {
        src: require("../../assets/commons/VipLevels/vip31.png"),
    },
    32: {
        src: require("../../assets/commons/VipLevels/vip32.png"),
    },
    33: {
        src: require("../../assets/commons/VipLevels/vip33.png"),
    },
    34: {
        src: require("../../assets/commons/VipLevels/vip34.png"),
    },
    35: {
        src: require("../../assets/commons/VipLevels/vip35.png"),
    },
    36: {
        src: require("../../assets/commons/VipLevels/vip36.png"),
    },
    37: {
        src: require("../../assets/commons/VipLevels/vip37.png"),
    },
    38: {
        src: require("../../assets/commons/VipLevels/vip38.png"),
    },
    39: {
        src: require("../../assets/commons/VipLevels/vip39.png"),
    },
    40: {
        src: require("../../assets/commons/VipLevels/vip40.png"),
    },
    41: {
        src: require("../../assets/commons/VipLevels/vip41.png"),
    },
    42: {
        src: require("../../assets/commons/VipLevels/vip42.png"),
    },
    43: {
        src: require("../../assets/commons/VipLevels/vip43.png"),
    },
    44: {
        src: require("../../assets/commons/VipLevels/vip44.png"),
    },
    45: {
        src: require("../../assets/commons/VipLevels/vip45.png"),
    },
    46: {
        src: require("../../assets/commons/VipLevels/vip46.png"),
    },
    47: {
        src: require("../../assets/commons/VipLevels/vip47.png"),
    },
    48: {
        src: require("../../assets/commons/VipLevels/vip48.png"),
    },
    49: {
        src: require("../../assets/commons/VipLevels/vip49.png"),
    },
    50: {
        src: require("../../assets/commons/VipLevels/vip50.png"),
    }
}